/* reset.css */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600);
html {
  margin: 0;
  padding: 0;
  border: 0;
}

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, dialog, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.5;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  float: none !important;
}

table, th, td {
  vertical-align: middle;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
}

blockquote, q {
  quotes: "" "";
}

a img {
  border: none;
}

*, *:after, *:before {
  box-sizing: border-box;
}

.clear:before, .clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

.clear {
  clear: both;
  *zoom: 1;
}

.hidden {
  display: none;
  visibility: hidden;
}

.center {
  margin: 0 auto;
}

.f-left {
  float: left !important;
}

.f-right {
  float: right !important;
}

.t-left {
  text-align: left !important;
}

.t-right {
  text-align: right !important;
}

.t-center {
  text-align: center !important;
}

@media (max-width: 480px) {
  .row, .row-no-gap {
    display: block;
    width: 100%;
  }
  [class^='col'] {
    width: 100%;
    margin: 0 0 1.5em 0;
  }
}

@media (min-width: 480px) {
  .row-no-gap {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }
  .row-no-gap [class^='col-s'] {
    margin-left: 0;
  }
  .row-no-gap .col-s-1 {
    width: 8.33333%;
  }
  .row-no-gap .col-s-2 {
    width: 16.66667%;
  }
  .row-no-gap .col-s-3 {
    width: 25%;
  }
  .row-no-gap .col-s-4 {
    width: 33.33333%;
  }
  .row-no-gap .col-s-5 {
    width: 41.66667%;
  }
  .row-no-gap .col-s-6 {
    width: 50%;
  }
  .row-no-gap .col-s-7 {
    width: 58.33333%;
  }
  .row-no-gap .col-s-8 {
    width: 66.66667%;
  }
  .row-no-gap .col-s-9 {
    width: 75%;
  }
  .row-no-gap .col-s-10 {
    width: 83.33333%;
  }
  .row-no-gap .col-s-11 {
    width: 91.66667%;
  }
  .row-no-gap .col-s-12 {
    width: 100%;
  }
  .row-no-gap .gap-s-1 {
    margin-left: 8.33333%;
  }
  .row-no-gap .gap-s-2 {
    margin-left: 16.66667%;
  }
  .row-no-gap .gap-s-3 {
    margin-left: 25%;
  }
  .row-no-gap .gap-s-4 {
    margin-left: 33.33333%;
  }
  .row-no-gap .gap-s-5 {
    margin-left: 41.66667%;
  }
  .row-no-gap .gap-s-6 {
    margin-left: 50%;
  }
  .row-no-gap .gap-s-7 {
    margin-left: 58.33333%;
  }
  .row-no-gap .gap-s-8 {
    margin-left: 66.66667%;
  }
  .row-no-gap .gap-s-9 {
    margin-left: 75%;
  }
  .row-no-gap .gap-s-10 {
    margin-left: 83.33333%;
  }
  .row-no-gap .gap-s-11 {
    margin-left: 91.66667%;
  }
  .row-no-gap .gap-s-12 {
    margin-left: 100%;
  }
}

@media (min-width: 800px) {
  .row-no-gap {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }
  .row-no-gap [class^='col-m'] {
    margin-left: 0;
  }
  .row-no-gap .col-m-1 {
    width: 8.33333%;
  }
  .row-no-gap .col-m-2 {
    width: 16.66667%;
  }
  .row-no-gap .col-m-3 {
    width: 25%;
  }
  .row-no-gap .col-m-4 {
    width: 33.33333%;
  }
  .row-no-gap .col-m-5 {
    width: 41.66667%;
  }
  .row-no-gap .col-m-6 {
    width: 50%;
  }
  .row-no-gap .col-m-7 {
    width: 58.33333%;
  }
  .row-no-gap .col-m-8 {
    width: 66.66667%;
  }
  .row-no-gap .col-m-9 {
    width: 75%;
  }
  .row-no-gap .col-m-10 {
    width: 83.33333%;
  }
  .row-no-gap .col-m-11 {
    width: 91.66667%;
  }
  .row-no-gap .col-m-12 {
    width: 100%;
  }
  .row-no-gap .gap-m-1 {
    margin-left: 8.33333%;
  }
  .row-no-gap .gap-m-2 {
    margin-left: 16.66667%;
  }
  .row-no-gap .gap-m-3 {
    margin-left: 25%;
  }
  .row-no-gap .gap-m-4 {
    margin-left: 33.33333%;
  }
  .row-no-gap .gap-m-5 {
    margin-left: 41.66667%;
  }
  .row-no-gap .gap-m-6 {
    margin-left: 50%;
  }
  .row-no-gap .gap-m-7 {
    margin-left: 58.33333%;
  }
  .row-no-gap .gap-m-8 {
    margin-left: 66.66667%;
  }
  .row-no-gap .gap-m-9 {
    margin-left: 75%;
  }
  .row-no-gap .gap-m-10 {
    margin-left: 83.33333%;
  }
  .row-no-gap .gap-m-11 {
    margin-left: 91.66667%;
  }
  .row-no-gap .gap-m-12 {
    margin-left: 100%;
  }
}

/* Colors */
/* Base */
html {
  font-family: 'Open Sans', sans-serif;
  font-size: 26px;
  color: #b5b7b7;
}

body {
  background-color: #000000;
  font-size: .8em;
}

@media (min-width: 460px) {
  body {
    font-size: .85em;
  }
}

@media (min-width: 600px) {
  body {
    font-size: .9em;
  }
}

@media (min-width: 900px) {
  body {
    font-size: .95em;
  }
}

@media (min-width: 1050px) {
  body {
    font-size: 1em;
  }
}

h2 {
  display: inline-block;
  background-color: #000000;
  padding: 0 .3em;
  color: #ffffff;
  font-weight: 300;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: #b5b7b7;
  transition: color .6s;
}

a:hover {
  color: #000000;
}

p {
  margin-bottom: 1.75em;
}

em {
  color: #000000 !important;
}

/* Layout */
#wrapper {
  max-width: 1350px;
  margin: 0 auto;
  padding: .5em .75em;
}

/* Header */
.logo {
  display: inline-block;
  background-image: url("../images/logo.jpg");
  width: 220px;
  height: 50px;
  background-size: 220px 34px;
  background-position: center bottom;
  background-repeat: no-repeat;
  text-indent: -9999px;
  /* +break(460)*/
  /* 	width: 327px*/
  /* 	height: 50px*/
  /* 	background-size: 327px 50px*/
}

@media (min-width: 360px) {
  .logo {
    width: 260px;
    height: 50px;
    background-size: 260px 40px;
  }
}

header nav {
  position: fixed;
  left: 0;
  right: 0;
  top: calc(50px + .75em);
  display: none;
  padding: 0 .75em;
  z-index: 99;
}

header nav li {
  display: block;
  list-style-type: none;
}

header nav li a {
  display: block;
  background-color: #ffffff;
  border-bottom: 1px solid #000000;
  padding: .25em 1.5em;
  color: #000000;
  font-size: .85em;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .6s;
}

header nav li a:hover, header nav li a.active {
  background-color: #b5b7b7;
}

@media (min-width: 860px) {
  header {
    display: table;
    width: 100%;
  }
  header nav {
    position: relative;
    top: 0;
    display: table-cell !important;
    padding: 0;
    text-align: right;
    vertical-align: bottom;
    line-height: .6em;
  }
  header nav li {
    display: inline-block;
  }
  header nav li a {
    display: inline-block;
    background-color: transparent;
    padding: 0;
    margin-left: 1em;
    color: #b5b7b7;
    font-size: .55em;
    transition: color .6s;
  }
  header nav li a:hover, header nav li a.active {
    color: #ffffff;
    background-color: transparent;
  }
}

#menu-btn {
  float: right;
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  padding: 5px;
  background-color: #ffffff;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#menu-btn span {
  display: block;
  position: absolute;
  height: 4px;
  width: 32px;
  background: #000000;
  border-radius: 2px;
  opacity: 1;
  left: 8.5px;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#menu-btn span:nth-child(1) {
  top: 15px;
  transform-origin: left center;
}

#menu-btn span:nth-child(2) {
  top: 23px;
  transform-origin: left center;
}

#menu-btn span:nth-child(3) {
  top: 31px;
  transform-origin: left center;
}

@media (min-width: 860px) {
  #menu-btn {
    display: none;
  }
}

.nav-open #menu-btn span:nth-child(1) {
  transform: rotate(45deg);
  top: 11px;
  left: 13px;
}

.nav-open #menu-btn span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.nav-open #menu-btn span:nth-child(3) {
  transform: rotate(-45deg);
  top: 34px;
  left: 13px;
}

/* Content */
#content {
  margin-top: .25em;
}

article {
  display: flex;
  padding: 4em 1.5em;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: .75em;
  text-align: center;
}

.row-no-gap article {
  text-align: left;
}

.row .photo,
.row-no-gap .photo {
  min-height: 300px;
  background-size: cover;
  background-position: center center;
}

ul {
  list-style-type: none;
}

#slider .lazyload {
  display: flex;
  height: 100%;
}

.loader {
  padding: 4em;
  width: 100%;
  text-align: center;
}

.grid-stack {
  display: none;
}

.grid-stack-item {
  display: block !important;
}

/* Footer */
footer {
  margin-top: .5em;
  line-height: .6em;
  text-align: center;
}

footer a {
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  font-size: .4em;
  opacity: 1;
  transition: .6s opacity, 0s color;
}

footer a:hover {
  color: #b5b7b7;
  opacity: .6;
}

footer .social-icons {
  display: block;
  margin-bottom: .5em;
  vertical-align: middle;
}

footer .social-icons svg {
  position: absolute;
  bottom: 7px;
  left: 6px;
  width: 27px;
  height: 27px;
  fill: #000000;
}

footer .social-icons a.instagram svg {
  width: 44px;
  height: 44px;
  left: 0;
  bottom: 0;
  fill: #ffffff;
}

footer .social-icons a.facebook svg {
  width: 43px;
  height: 43px;
  bottom: -1px;
  right: 3px;
}

footer .social-icons a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  overflow: hidden;
  border-radius: 2px;
}

footer .social-icons a.facebook, footer .social-icons a.youtube {
  background-color: #ffffff;
}

footer .social-icons a.instagram {
  background-color: transparent;
}

footer .social-icons a:hover.facebook, footer .social-icons a:hover.vimeo {
  background-color: #b5b7b7;
}

footer .social-icons a:hover.instagram svg {
  fill: #b5b7b7;
}

@media (min-width: 860px) {
  footer {
    margin-top: .2em;
    text-align: left;
  }
  footer .social-icons {
    display: inline-block;
    float: right;
    margin-bottom: 0;
  }
  footer .social-icons svg {
    bottom: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
  }
  footer .social-icons a.instagram svg {
    width: 18.5px;
    height: 18.5px;
  }
  footer .social-icons a.facebook svg {
    width: 18px;
    height: 18px;
    bottom: -1px;
    right: 3px;
  }
  footer .social-icons a {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    margin-left: 0;
  }
}
